import React from "react";
import StepThree from "../../components/cards/onboardingCard/stepThree";
import { LiveChatWidget } from '@livechat/widget-react'

const UserDetails = () => {
    return (
        <div className="bg bg-img">
          <div className="mid-card">
            <StepThree />
          </div>
          {/* <LiveChatWidget
      license="14411064"
      visibility="minimized"
    /> */}
        </div>
      );
}

export default UserDetails;